class Filter {

  constructor(elt, doFilterFct = null, entryAttributeName = "data-value", siblingFilter = null, activeValue = "all") {
    this.elt = elt;
    this.attributeName = entryAttributeName;
    this.doFilterFct = doFilterFct;
    this.activeValue = activeValue;
    this.siblingFilter = siblingFilter;
    this.init();
  }

  init() {
    this.openFilterListener();
    this.doFilterListener();
    setTimeout(()=>{this.doFilter()},0);
  }

  openFilterListener() {
    let self = this;
    this.elt.addEventListener('click', () => {
      this.toggleOpen();
      if (this.siblingFilter != null) {
        this.siblingFilter.closeFilter();
      }
    })
  }

  toggleOpen() {
    this.elt.classList.contains('open') ? this.closeFilter() : this.openFilter();
  }

  closeFilter() {this.elt.classList.remove('open')}
  openFilter() {this.elt.classList.add('open')}


  doFilterListener() {
    let optionsLi = this.elt.getElementsByTagName('li');
    for(let i = 0; i < optionsLi.length; i++) {
      optionsLi[i].addEventListener('click', (e) => {
        e.stopPropagation();
        let value = optionsLi[i].getAttribute('data-value');
        if(value !== this.activeValue) {
          this.activeValue = value;
          this.elt.classList.remove('label-down');
          this.doChangeActiveFilter(optionsLi[i]);
          setTimeout(()=>{this.doFilter()},0);
        }
      })
    }
  }

  doFilter() {
    if (this.doFilterFct !== null) {
      this.doFilterFct(this);
    }
  }

  doChangeActiveFilter(newActiveEntry) {
    let selected = this.elt.getElementsByClassName('selected')[0];
    let filterPTag = newActiveEntry.getElementsByTagName('p')[0];
    selected.innerHTML = filterPTag.innerHTML;
    this.closeFilter();
    this.elt.getElementsByClassName('active')[0].classList.remove('active');
    newActiveEntry.classList.add("active");
  }

  updateMyself(availableValues) {
    let optionsLi = this.elt.getElementsByTagName('li');
    for(let i = 0; i < optionsLi.length; i++) {
      let value = optionsLi[i].getAttribute('data-value');
      if(availableValues.indexOf(value) === -1) {
        optionsLi[i].classList.add('inactive');
      } else {
          optionsLi[i].classList.remove('inactive');
      }
    }
  }

  activateAll() {
    this.elt.getElementsByClassName('inactive')[0].classList.remove('inactive');
  }
}

export default Filter;
