const navigation = {

    burgerActiveClass: 'is-active',
    burgerNotActiveClass: 'not-active',
    burger: null,
    menuNotVisibleClass: 'not-visible',
    menuDeployedClass: 'deployed',
    menu: null,
    closed: true,

    init() {
        navigation.burger = document.getElementsByClassName('s-burger')[0];
        navigation.menu = document.getElementById(navigation.burger.getAttribute('data-open'));
        navigation.addListeners();
    },

    addListeners() {
        navigation.burger.addEventListener('click', () => navigation.openOrClose());
        document.body.addEventListener('click', (e) => {
            if(!navigation.burger.contains(e.target) && !navigation.menu.contains(e.target)) {
                navigation.close();
            }
        })
    },

    openOrClose() {
      if(navigation.burger.classList.contains(navigation.burgerActiveClass)){
          navigation.close();
      } else {
          navigation.open();
      }
    },


    close() {
        if(!navigation.closed) {
            navigation.burger.classList.remove(navigation.burgerActiveClass);
            navigation.burger.classList.add(navigation.burgerNotActiveClass);
            navigation.menu.classList.remove(navigation.menuDeployedClass);
            navigation.menu.classList.add(navigation.menuNotVisibleClass);
            document.getElementsByTagName('body')[0].classList.remove('navexp');
            navigation.closed = true;
        }
    },

    open() {
        if (navigation.closed) {
            navigation.burger.classList.remove(navigation.burgerNotActiveClass);
            navigation.burger.classList.add(navigation.burgerActiveClass);
            navigation.menu.classList.remove(navigation.menuNotVisibleClass);
            navigation.menu.classList.add(navigation.menuDeployedClass);
            document.getElementsByTagName('body')[0].classList.add('navexp');
            navigation.closed = false;
        }
    }
};

module.exports = navigation;


