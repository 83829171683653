import sukoaProject from './app';
import Filter from './s-filter';
const worksList = {
    list: null,
    groups: [],
    elements: null,
    ensembleFilter: null,
    instrumentsFilter: null,
    init() {
        worksList.list = document.querySelector('.workslist');
        if(worksList.list) {
            let groups = worksList.list.getElementsByClassName('group');
            if(groups.length > 0) {
                for (let i = 0; i < groups.length; i++) {
                    worksList.groups.push({'groupElt': groups[i], 'inputs': groups[i].getElementsByClassName('input')});
                }
            }


            worksList.elements = worksList.list.getElementsByClassName('input');
            worksList.initListeners();
            worksList.initFilters();
        }
    },

    initListeners() {
        let works = worksList.list.querySelectorAll('.work');
        sukoaProject.each(works, work => work.addEventListener('click', (event) => {
            let detailsWrapper = work.querySelector('.details-wrapper');
            let button = detailsWrapper.querySelector('button');

            if(event.target.classList.contains('work') || event.target.classList.contains('cell') ) {

                worksList.showHideDetails(work, detailsWrapper);

            } else if(event.target.tagName === 'BUTTON' || event.target.classList.contains('file-icon')) { // button  clicked

                worksList.getAndShowForm(event, work, detailsWrapper, button);

            } else if(event.target.getAttribute('type') === 'submit') {

                event.preventDefault();
                worksList.sendFormShowResult(work, detailsWrapper, button);

            }
        }));
    },



    initFilters() {
        let ensembleFilter = worksList.list.getElementsByClassName('filter ensembles')[0];
        if(ensembleFilter) {
            worksList.ensembleFilter = new Filter(ensembleFilter, worksList.applyFilters, 'data-ensemble');
        }
        
        let instrumentsFilter = worksList.list.getElementsByClassName('filter instruments')[0];
        if(ensembleFilter) {
            worksList.instrumentsFilter = new Filter(instrumentsFilter, worksList.applyFilters, 'data-instruments', worksList.ensembleFilter);
            worksList.ensembleFilter.siblingFilter = worksList.instrumentsFilter;
        }
    },


    applyFilters(filter) {
        let availableValues = '';
        let siblingAvailableValues = '';
        for(let g = 0; g < worksList.groups.length; g++) {
            let outList = [];
            let inList = [];

            let group = worksList.groups[g];
            let hiddenCount = 0;
            for(let i = 0; i < group.inputs.length; i++) {
                let input = group.inputs[i];
                let attribute = input.getAttribute(filter.attributeName);
                let filterActiveVal = filter.activeValue;
                let siblingAttribute = input.getAttribute(filter.siblingFilter.attributeName);
                let siblingActiveVal = filter.siblingFilter.activeValue;
                if(attribute.indexOf(filterActiveVal) !== -1 && siblingAttribute.indexOf(siblingActiveVal) !== -1) {

                    if(input.classList.contains('out')) {
                        inList.push(input);
                    }
                    if(availableValues.indexOf(attribute) === -1) {
                        availableValues += attribute;
                    }
                    if(siblingAvailableValues.indexOf(siblingAttribute) === -1) {
                        siblingAvailableValues += siblingAttribute;
                    }
                } else {
                    hiddenCount++;
                    if(!input.classList.contains('out')) {
                        outList.push(input);
                    }
                }
            }
            if(hiddenCount >= group.inputs.length) {
                if(!group.groupElt.classList.contains('out')) {
                    outList.push(group.groupElt);
                }
            } else {
                if(group.groupElt.classList.contains('out')) {
                    group.groupElt.classList.remove('out');
                    inList.push(group.groupElt);
                }
            }
            worksList.inOutHandling(outList, inList);
        }
        // filter.updateMyself(availableValues);
        filter.siblingFilter.updateMyself(siblingAvailableValues);
    },

    inOutHandling(outList, inList) {
        let time = 0;
        let delay = 20;

        if(outList.length > 0) {
            for(let i = 0; i < outList.length; i++) {
                let elem = outList[i];
                if(elem.classList.contains('input')) {
                    elem.style.maxHeight = elem.clientHeight + 'px';
                }
                window.setTimeout(() => {
                    elem.classList.add('out');
                }, time);
                time += delay;
            }
        }

        time = 0;

        if(inList.length > 0) {
            for(let i = 0; i < inList.length; i++) {
                let elem = inList[i];
                if(elem.classList.contains('input')) {
                    elem.style.maxHeight = 'none';
                }
                window.setTimeout(() => {
                    elem.classList.remove('out');
                }, time);
                time += delay;
            }
        }
    },

    showHideDetails(work, detailsWrapper){
        if(work.classList.contains('open')) {
            detailsWrapper.style.maxHeight = 0;
            detailsWrapper.style.height = 0;
            work.classList.remove('open');
        } else {
            worksList.resizeWorkDetails(work, detailsWrapper);
            work.classList.add('open');
            worksList.loadIframe(detailsWrapper);
        }
    },


    loadIframe(detailsWrapper) {
        let iframe = detailsWrapper.getElementsByTagName('iframe')[0];
        let loader = detailsWrapper.getElementsByClassName('loader')[0];
        if(loader) {
            loader.classList.add('active');
        }

        if(iframe && !detailsWrapper.classList.contains('iframe-loaded')) {

            let src = iframe.getAttribute('data-src');
            if(src) {
                iframe.addEventListener('load', (e) => {
                    detailsWrapper.classList.add('iframe-loaded');
                });
                window.setTimeout(() => {
                    iframe.src = src;
                },200);
            }
        }
    },

    getAndShowForm(event, work, detailsWrapper, button) {
        if(button.hasAttribute('data-href')) {
            if(!button.classList.contains('deactivated')) {

                sukoaProject.ajxGet(button.getAttribute('data-href'), (req) => {
                    let tempo = document.createElement('div');
                    button.classList.add('deactivated');
                    tempo.innerHTML = req.responseText;
                    let form = tempo.querySelector('.form');
                    if(form) {
                        button.insertAdjacentElement('afterend', form);
                        worksList.resizeWorkDetails(work, detailsWrapper);
                    }
                })

            } else  {
                let formWrapper = work.getElementsByClassName('form-wrapper')[0];

                if(formWrapper) {
                    if(formWrapper.classList.contains('hidden')) {
                        formWrapper.style.display = 'block';
                        worksList.resizeWorkDetails(work, detailsWrapper);
                        window.setTimeout(()=> {
                            formWrapper.classList.remove('hidden');
                        }, 50);
                    } else {
                        formWrapper.classList.add('hidden');
                        window.setTimeout(()=> {
                            formWrapper.style.display = "none";
                            worksList.resizeWorkDetails(work, detailsWrapper);
                        }, 250);
                    }
                }
            }
        }
    },

    sendFormShowResult(work, detailsWrapper, button) {
        let form = document.forms[work.querySelector('form').id];
        let dataString = '';
        let validated = true;
        for(let i  = 0; i < form.elements.length; i++) {
            if(form.elements[i].tagName !== "FIELDSET") {
                if(form.elements[i].value && form.elements[i].name) {
                    form.elements[i].classList.remove('error');
                    dataString += dataString === '' ? '?' : '&';
                    dataString += form.elements[i].name + '=' + form.elements[i].value;
                } else if(form.elements[i].hasAttribute('required')) {
                    form.elements[i].classList.add('error');
                    validated = false;
                }
            }
        }

        //send score form
        if(validated && dataString) {
            let httpReq = new XMLHttpRequest();
            httpReq.open('POST', button.getAttribute('data-href'), true);
            httpReq.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

            let formWrapper = form.parentNode;
            formWrapper.classList.add('loading');

            httpReq.onreadystatechange = function() {

                if(this.readyState === XMLHttpRequest.DONE && this.status === 200) {

                    let temp = document.createElement('div');
                    temp.innerHTML = httpReq.responseText;

                    let prevResponse = form.parentNode.getElementsByClassName('form')[0];
                    if(prevResponse) { formWrapper.removeChild(prevResponse);}

                    let formResp = temp.querySelector('.form');
                    if(formResp) {
                        form.insertAdjacentElement('afterend',formResp);
                        if(formResp.getElementsByClassName('success').length > 0) {
                            formWrapper.removeChild(form)
                        }
                        window.setTimeout(()=>{
                            worksList.resizeWorkDetails(work, detailsWrapper);
                            formWrapper.classList.remove('loading');
                        }, 300);
                    }
                }

            };

            httpReq.send(dataString);
        }
    },

    resizeWorkDetails(work, detailsWrapper) {
        let detailHeight = work.querySelector('.details').clientHeight;
        detailsWrapper.style.maxHeight = detailHeight + "px";
        detailsWrapper.style.height = detailHeight + "px";
    }
};

module.exports = worksList;