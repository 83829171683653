const navigation = require('./navigation');
const worksList = require('./workslist');

const sukoaProject = {
    isTouchUser: false,

    init() {
        sukoaProject.detectTouchUser();
        navigation.init();
        worksList.init();

    },

    removeLoader(elem) {
        let parent = elem.parentNode;
        let loaders = parent.getElementsByClassName('loader');
        console.log(loaders);
        sukoaProject.each(loaders, loader => { parent.removeChild(loader)});
    },

    detectTouchUser() {
        sukoaProject.isTouchUser = false;
        document.body.classList.remove("toucher-user");

        if(window.innerWidth < 640) {
            setTouchUser();
        } else {
            window.addEventListener('touchstart', function onFirstTouch() {
                setTouchUser();
                window.removeEventListener('touchstart', onFirstTouch, {passive: true});
            }, {passive: true});
        }

        function setTouchUser() {
            document.body.classList.add("touch-user");
            sukoaProject.isTouchUser = true;
        }
    },

    ajxGet(url, fallback=null){
        let req = new XMLHttpRequest();
        req.onreadystatechange = function() {
            if(this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                if(fallback) {
                    fallback(req);
                }
            }
        };
        req.open('GET', url, true);
        req.send(null);
    },

    each(list, fn) {
        for(let i = 0; i < list.length; i++) {
            fn(list[i]);
        }
    }


};

window.sukoaUtils = {
    dcmadr (nnnn) {
        var a = "";
        for (let i = 0, m = nnnn.length; i < m; i++) {
            if (i % 3 == 0) {
                a += String.fromCharCode(nnnn.substr(i, 3));
            }
        }
        location.href = (a);
    }
};

document.addEventListener('DOMContentLoaded', () => {
    sukoaProject.init();
});

export default sukoaProject;